import logo from './logo.svg';
import './App.css';
import school from "./Paradise_logo.png"
import man from "./man.png"
import tick from "./righttik.png"
import { useEffect } from 'react';

function App() {

  const handleClick = () => {
    fetch("https://graph.facebook.com/v19.0/1163525137978061/events", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: [
          {
            action_source: "website",
            event_id: 12345,
            event_name: "Subscribe",
            event_time: 1712734077,
            user_data: {
              client_ip_address: "254.254.254.254",
              client_user_agent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:63.0) Gecko/20100101 Firefox/63.0",
              em: "f660ab912ec121d1b1e928a0bb4bc61b15f5ad44d5efdc4e1c92a25e99b8e44a"
            }
          }
        ],
        test_event_code: "TEST52006",
        access_token: "EAAdM5RCwunwBO5VAVgzZC6pinNfDG0HNa57gFnCCVXtl28MHv0UxWRxttZAsL4Fwz1mYnMEoc8Vlu2CrpL7IDSeKY5CQH0Rq44iUKmp4fdZBnSFpLBhqC3W4pbJS0tnNYQqyatLLvh1yZCwNrLs2oU80rnT1EF6MTnoDtSUWZC9oZBfzHaM8fyxJe2XESBpye4AQZDZD"
      })
    })
      .then(response => {
        if (response.ok) {
          console.log('Event tracked successfully');
        } else {
          console.error('Failed to track event');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    };

  return (
    <div className="App">
      <div className="blue-header">
        don't have <strong>Telegram</strong> yet? Try it now!
      </div>
      <div className="team-logo-wrapper">
        <div className="team-logo bg-team">
        </div>
        <div className="text-wrapper">
          <h1 className="avitr-t-name avitor-icon-link">OPTION'S PARADISE™</h1>
          <p className="gray-text">61,092 subscribers</p>
          <p className="info-text"> <img src={man} className="user-icon" alt="" /> Option Buying In Nifty And Banknifty <br /><br />START YOUR PROFITABLE TRADING JOURNEY FROM TODAY</p>
          <p className="Link-channer "> <img src={tick} className="user-icon" alt="" /> India's best channel for Option Buying In Nifty And Banknifty</p>
          <a id="subscribeButton" href="https://telegram.me/+vUYjwdPFZeI5NTNl" className="view-btn" onClick={handleClick} target="_blank" rel="noopener noreferrer" > View in telegram</a>
          <p className="info-link-text">if you have <strong>Telegram</strong> you can view and join <a id="subscribeButton" href="https://telegram.me/+vUYjwdPFZeI5NTNl" onClick={handleClick} className="avitor-link avitor-icon-link" target="_blank" rel="noopener noreferrer"> <img src={school} className="user-icon" alt="" /> <strong>OPTION'S PARADISE™</strong> </a> right away.</p>
        </div>
      </div>
    </div>

  );
}

export default App;
